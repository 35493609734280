import React from "react"

// import DesktopLandingBody from "../components/DesktopLandingBody"
// import MobileLandingBody from "../components/MobileLandingBody"
import loadingSpinner from "../imgs/loading-spinner.svg"

const IndexPage = () => (
  <React.Fragment>
    <div style={{ textAlign: "center" }}>
      <img src={loadingSpinner} alt="spinner" />
    </div>
  </React.Fragment>
)

export default IndexPage
